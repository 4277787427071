import * as React from 'react'

interface NavBarContextType {
  isSpecialFrontPageEnabled: boolean
  setIsSpecialFrontPageEnabled: (value: boolean) => void
  darkThemed: boolean
  setDarkThemed: (value: boolean) => void
}

const NavBarContext = React.createContext<NavBarContextType>({
  darkThemed: true,
  isSpecialFrontPageEnabled: false,
  setDarkThemed: () => undefined,
  setIsSpecialFrontPageEnabled: () => undefined,
})

export const NavBarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [darkThemed, setDarkThemed] = React.useState(true)
  const [isSpecialFrontPageEnabled, setIsSpecialFrontPageEnabled] = React.useState(false)

  const ctx = {
    isSpecialFrontPageEnabled,
    setIsSpecialFrontPageEnabled,
    darkThemed,
    setDarkThemed,
  }
  return <NavBarContext.Provider value={ctx}>{children}</NavBarContext.Provider>
}

export const useNavBarContext = () => React.useContext(NavBarContext)
