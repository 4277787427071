import { Pane } from 'evergreen-ui'
import * as React from 'react'
import NavLogo from './NavLogo'
import { useBreakpoint } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'
import { navItems } from './navItems'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { NavBarItem } from './NavBarItem'
import { useWindowScrollPosition } from 'rooks'
import { NavBarAuth } from './NavBarAuth'
import { NavBarMobileMenu } from './NavBarMobileMenu'
import styled, { css } from 'styled-components'
import { useNavBarContext } from './NavBarContextProvider'

const NavItemPane = styled(Pane)<{ $isScrolled?: boolean; $specialFrontpage?: boolean }>`
  ${({ theme, $specialFrontpage, $isScrolled }) => css`
  display: flex;
  column-gap: ${theme.spacing.xs};
  .navParent {
    .navElement {
      color: ${!$isScrolled && $specialFrontpage ? theme.colors.fiTint : undefined};
      &:hover {
        color: ${!$isScrolled && $specialFrontpage ? theme.colors.fiBlue40 : undefined};
      }
    }
    & > :first-child {
      color: ${!$isScrolled && $specialFrontpage ? theme.colors.fiText : undefined}; 
      &:hover {
        color: ${!$isScrolled && $specialFrontpage ? theme.colors.fiBlue : undefined};
      }
    }
  }
`}`

export const NavBar = () => {
  const ctx = useNavBarContext()
  const { colors, spacing } = useCustomTheme()
  const { medium, large } = useBreakpoint()
  const position = useWindowScrollPosition()
  const location = useLocation()
  const specialFrontpage = ctx.isSpecialFrontPageEnabled && location.pathname === '/'
  const isScrolled = position.scrollY > 1

  return (
    <Pane
      display='flex'
      position='sticky'
      width='100%'
      height={80}
      top={0}
      zIndex={10}
      justifyContent='center'
      overflow='hidden'
      background={isScrolled ? colors.white : undefined}
      transition='0.4s'
      boxShadow={isScrolled ? '0px 9px 10px -8px rgba(45, 51, 64, 0.18)' : 'inherit'}
      paddingX={spacing.s}
    >
      <Pane
        width='100%'
        maxWidth={1260}
        display='flex'
        justifyContent='space-between'
        height='100%'
        alignItems='center'
      >
        <Pane is={RouterLink} width={medium ? 30 : 'auto'} overflow='hidden' to='/'>
          <NavLogo small={medium} />
        </Pane>

        {large ? (
          <NavBarMobileMenu />
        ) : (
          <NavItemPane $isScrolled={isScrolled} $specialFrontpage={specialFrontpage}>
            <Pane className='navParent' display='flex' columnGap={spacing.xs}>
              {navItems.map((navItem) => (
                <NavBarItem key={navItem.key} {...navItem} />
              ))}
            </Pane>
            <NavBarAuth specialFrontpage={specialFrontpage} isScrolled={isScrolled} />
          </NavItemPane>
        )}
      </Pane>
    </Pane>
  )
}
