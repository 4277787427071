import * as React from 'react'
import { Pane, Heading, Button, DialogProps, ButtonProps } from 'evergreen-ui'
import { Message } from '../../lib/i18n/types'
import { FormattedMessage } from 'react-intl'
import { useCustomTheme } from '../../lib/theme'
import { EnhancedDialog } from '../EnhancedDialog'
import { ConfirmationButton } from './ConfirmationButton'

type ConfirmationDialogProps = {
  disableConfirmButton?: boolean
  confirmButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  onConfirm: () => void
  onCancel: () => void
  isLoading?: boolean
  open: boolean
  dialogProps?: DialogProps
  delayToConfirmInMs?: number
  children?: React.ReactNode
} & TitleProps &
  ConfirmButtonLabelProps &
  CancelButtonLabelProps

type TitleProps = { title: Message; titleAsString?: never } | { title?: never; titleAsString: string }
type ConfirmButtonLabelProps =
  | { confirmButtonTitle: Message; confirmButtonTitleAsString?: never }
  | { confirmButtonTitle?: never; confirmButtonTitleAsString: string }
  | { confirmButtonTitle?: never; confirmButtonTitleAsString?: never }
type CancelButtonLabelProps =
  | { cancelButtonTitle: Message; cancelButtonTitleAsString?: never }
  | { cancelButtonTitle?: never; cancelButtonTitleAsString: string }
  | { cancelButtonTitle?: never; cancelButtonTitleAsString?: never }

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  titleAsString,
  confirmButtonTitle,
  cancelButtonTitle,
  confirmButtonTitleAsString,
  cancelButtonTitleAsString,
  disableConfirmButton = false,
  onConfirm,
  onCancel,
  children,
  isLoading,
  open,
  confirmButtonProps,
  cancelButtonProps,
  dialogProps,
  delayToConfirmInMs,
}) => {
  const { spacing } = useCustomTheme()

  return (
    <EnhancedDialog
      isShown={open}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      fullscreen={false}
      {...dialogProps}
    >
      <Pane display='flex' flexDirection='column' gap={spacing.s} justifyContent='space-between'>
        <Heading size={400} margin={0}>
          {title ? <FormattedMessage id={title} /> : titleAsString}
        </Heading>
        <Pane>{children}</Pane>
        <Pane display='flex' justifyContent='flex-end' flexDirection='row' gap={spacing.s}>
          <Button autoFocus onClick={onCancel} size='small' {...cancelButtonProps}>
            {cancelButtonTitleAsString || <FormattedMessage id={cancelButtonTitle ?? 'cancel'} />}
          </Button>
          <ConfirmationButton
            width={175}
            isLoading={isLoading}
            onClick={onConfirm}
            appearance='primary'
            size='small'
            {...confirmButtonProps}
            delayInMs={delayToConfirmInMs}
            disabled={disableConfirmButton || !!confirmButtonProps?.disabled}
          >
            {({ canConfirm }) =>
              canConfirm && !disableConfirmButton && !confirmButtonProps?.disabled ? (
                confirmButtonTitleAsString || <FormattedMessage id={confirmButtonTitle ?? 'confirm'} />
              ) : (
                <FormattedMessage id='wait' />
              )
            }
          </ConfirmationButton>
        </Pane>
      </Pane>
    </EnhancedDialog>
  )
}
