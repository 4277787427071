import { Pane } from 'evergreen-ui'
import React from 'react'
import { useBreakpoint } from '/fiweb/lib'
import styled, { css } from 'styled-components'
import { HeroBanner } from './HeroBanner'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

type SliderProps = Slider['props']

interface CarouselItem {
  id: string
  tag?: string | null
  title?: string | null
  subheader?: string | null
  content?: string | null
  backgroundImageUrl?: string | null
  buttonUrl?: string | null
  animated?: boolean | null
  darkThemed?: boolean | null
}

export type JsonDataType = Array<CarouselItem>

const HeroCarouselWrapper = styled(Pane)`
${({ theme }) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  > .slider-container {
    width: 100%;
    overflow: hidden;
  }
  .slick-dots {
    height: 20%;
    gap: 0px;
    align-items: center;
    justify-content: center;
    > li {
      height: auto;
      width: 50px;
      padding: 10px;
      margin: 0px;
      &.slick-active > div {
          background-color: white;
          transform: scale(1.2);
          transition: all 0.5s ease;
          mix-blend-mode: unset;
        }  
      > div {
        height: 8px;
        border-radius: 10px;
        transition: all 1.5s ease;
        mix-blend-mode: overlay;
        background-color: ${theme.colors.fiDark};
      }
    }
  }
`}
`

interface Props {
  items?: JsonDataType
  fade?: boolean
  speed?: number
}

export const HeroCarousel = ({ items, fade, speed = 500 }: Props) => {
  const { medium } = useBreakpoint()

  const settings: SliderProps = {
    dots: true,
    infinite: true,
    fade: fade ? true : undefined,
    speed,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'ease',
    appendDots: (dots) => (
      <div
        style={{
          display: 'flex',
          bottom: 0,
        }}
      >
        {dots}
      </div>
    ),
    customPaging: () => <div />,
  }

  return (
    <HeroCarouselWrapper
      style={medium ? { top: 'unset', marginBottom: 'unset' } : { top: '-80px', marginBottom: '-80px' }}
    >
      <div className='slider-container' style={{ maxWidth: medium ? '100vw' : '50vw' }}>
        <Slider {...settings}>
          {items?.map((item, i) => (
            <HeroBanner key={i} {...item} />
          ))}
        </Slider>
      </div>
    </HeroCarouselWrapper>
  )
}
