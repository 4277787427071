import React from 'react'
import { useRouteError } from 'react-router'
import { Button, Heading, Link, Pane, Paragraph } from 'evergreen-ui'
import { useBreakpoint } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'
import face from '/~/static/images/face.svg'
import { getBrowserClientDetails } from '/~/utils/browserData'
import { config } from '/~/utils/config'
import { useNotifyAboutErrorMutation } from '../types/graphql'

export const ErrorBoundary = () => {
  const error = useRouteError() as Error
  const { medium } = useBreakpoint()
  const { spacing } = useCustomTheme()
  const [notifyError] = useNotifyAboutErrorMutation()
  const [displayError, setDisplayError] = React.useState(false)

  const errorHandler = async () => {
    // Let's ignore certain common erros we know occur during re-deployment in prod
    // Once we find a solution to these issues, we can remove this check
    const ignoreStrings = [
      `'text/html' is not a valid JavaScript MIME type`,
      'dynamically imported module',
      'Importing a module script failed',
      `Failed to execute 'insertBefore' on 'Node'`,
    ]
    for (const str of ignoreStrings) {
      if (error?.message?.includes(str)) {
        window.location.reload()
        return
      }
    }
    try {
      setDisplayError(true)
      const { message, stack } = error
      const { appName, browserName, fullVersion, majorVersion, userAgent } = getBrowserClientDetails()

      if (config.disableErrorReporting) {
        return
      }

      await notifyError({
        variables: {
          error: {
            message,
            stack,
            url: window.location.href,
            appName,
            browserName,
            fullVersion,
            majorVersion,
            userAgent,
            componentStack: 'Deprecated',
            clientVersion: 'Deprecated',
            currentVersion: 'Deprecated',
          },
        },
      })
    } catch (e) {
      console.log('Feil under lagring av feil (ironisk nok)', e.message)
    }
  }

  React.useEffect(() => {
    if (error) {
      console.error(error)
      errorHandler()
    }
  }, [error])

  if (!displayError) {
    return null
  }

  return (
    <Pane
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='fixed'
      top={0}
      left={0}
      width='100vw'
      height='100vh'
    >
      <Pane display='flex' flexDirection='column' alignItems='center'>
        <Heading size={medium ? 500 : 600} marginBottom={medium ? spacing.xs : spacing.m} marginTop='9rem'>
          Beklager, en feil oppsto.
        </Heading>
        <img src={face} alt='two circles with a smile in the middle' />
        <Paragraph
          marginTop={medium ? spacing.xs : spacing.m}
          marginBottom={medium ? spacing.xs : spacing.m}
          maxWidth={medium ? 325 : 518}
          textAlign='center'
          color='colors.text.default'
        >
          Hendelsen er registrert og systemet har gitt beskjed til vår IT-avdeling. Vi vil rette opp feilen så snart det
          lar seg gjøre.
          <br />
          <br />
          Hvis feilen gjentar seg, eller om du ønsker nærmere informasjon, ta kontakt med oss direkte.
          <br />
          E-post: <Link href='mailto:support@folkeinvest.no'>support@folkeinvest.no</Link>
        </Paragraph>
        <Button
          onClick={() => {
            window.location.href = '/'
          }}
          appearance='primary'
        >
          Gå til forsiden
        </Button>
      </Pane>
    </Pane>
  )
}
