import * as React from 'react'
import { useAuthContext } from '/~/utils/AuthContext'
import { Button, InfoSignIcon, Pane, Paragraph, toaster, WarningSignIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Caption, ConfirmationDialog } from '/fiweb/components'
import { auth } from '/fiweb/lib'
import { config } from '/~/utils/config'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'

export const ConflictingSsnUser = () => {
  const { user, refetch } = useAuthContext()
  const navigate = useNavigate()
  const { spacing, colors } = useCustomTheme()
  const [loading, setLoading] = React.useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false)

  const conflictingUser = user.conflictingSsnUser

  const handleUpdateConfirm = async () => {
    setLoading(true)
    const res = await auth(config.authHost).updateSsnConflictUserWithCurrentUserLogin()
    setConfirmDialogOpen(false)
    if (res.updatedUser) {
      toaster.success('Du er nå logget inn med oppdatert brukerprofil.')
      await refetch()
      navigate('/konto')
    }
  }

  return (
    <>
      <Pane display='flex' flexDirection='column' width='100%' paddingY={spacing.xxs} paddingX={spacing.xxs}>
        <Pane display='flex' alignItems='center' gap={spacing.xs} paddingY={spacing.xxs}>
          <WarningSignIcon size={20} color={colors.fiRed} />
          <Paragraph size={400} fontWeight='bold'>
            Duplikatprofil funnet
          </Paragraph>
        </Pane>
        <Pane display='flex' flexDirection='column' gap={spacing.s}>
          <Paragraph size={300}>
            Under BankID-verifisering ble det oppdaget identitets-treff på en tidligere opprettet profil med følgende
            opplysninger:
          </Paragraph>
          <Pane display='flex' flexDirection='column' padding={spacing.xxs} background={colors.fiTint}>
            <Caption>
              <b>Opprettet:</b> {dayjs(conflictingUser.createdAt).format('DD.MM.YYYY')}
            </Caption>
            <Caption>
              <b>E-post:</b> {conflictingUser.email}
            </Caption>
            <Caption>
              <b>Adresse:</b> {conflictingUser.addressFields?.street}
            </Caption>
            <Caption>
              <b>Postnummer:</b> {conflictingUser.addressFields?.postalCode}
            </Caption>
            <Caption>
              <b>Poststed:</b> {conflictingUser.addressFields?.postalTown}
            </Caption>
            <Caption>
              <b>Telefon:</b> {conflictingUser.phone}
            </Caption>
          </Pane>
          <Pane display='flex' flexDirection='column'>
            <Paragraph size={300}>Vi tillater kun én verifisert brukerprofil per person.</Paragraph>
            <Paragraph size={300}>Dette medfører at du ikke kan verifisere profilen du er logget inn med nå.</Paragraph>
          </Pane>
          <Pane display='flex' flexDirection='column' gap={spacing.xs}>
            <Paragraph size={300}>
              Du kan benytte knappen under til å slå sammen din tidligere profil med epost-adressen til din nåværende (
              <b>{user.email}</b>).
            </Paragraph>
            <Button appearance='primary' size='small' alignSelf='flex-start' onClick={() => setConfirmDialogOpen(true)}>
              Oppdater verifisert profil
            </Button>
          </Pane>
        </Pane>
      </Pane>
      <ConfirmationDialog
        open={confirmDialogOpen}
        cancelButtonProps={{
          disabled: loading,
        }}
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={handleUpdateConfirm}
        titleAsString='Slå sammen profiler'
        dialogProps={{
          preventBodyScrolling: true,
        }}
        confirmButtonTitleAsString='Bekreft og oppdater'
        disableConfirmButton={loading}
        confirmButtonProps={{
          width: 200,
        }}
      >
        <Pane fontSize='14px'>
          <Paragraph size={300}>Ved å bekrefte dette valget, vil følgende skje:</Paragraph>
          <Pane display='flex' flexDirection='column' gap={spacing.xxs} padding={spacing.xxs}>
            <Paragraph size={300}>
              <b>1.</b> Vi slår sammen dine to profiler, der den verifiserte profilen (<b>{conflictingUser.email}</b>)
              utgjør grunnlaget.
            </Paragraph>
            <Paragraph size={300}>
              <b>2.</b> Innloggingsmetoder/-detaljer på din tidligere profil vil overskrives til fordel for din
              nåværende (<b>{user.email}</b>).
            </Paragraph>
            <Paragraph size={300}>
              <b>3.</b> Vi logger deg automatisk inn på din oppdaterte profil-side
            </Paragraph>
          </Pane>

          <Pane display='flex' flexDirection='column' gap={spacing.xxs} paddingY={spacing.xs}>
            <Pane display='flex' gap={spacing.xxs}>
              <Pane paddingTop={3}>
                <InfoSignIcon color={colors.fiBlue} />
              </Pane>

              <Paragraph size={300}>
                Det er viktig at du sørger for at adresse og telefonnummer er oppdatert og korrekt etter sammenslåingen,
                samt at du må godta siste vilkår, dersom det er lenge siden du benyttet din tidligere profil.
              </Paragraph>
            </Pane>
          </Pane>
        </Pane>
      </ConfirmationDialog>
    </>
  )
}
