import { Button, Pane, WarningSignIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import { getProviderIcon } from './utils'
import { SignicatProvider } from '/fiweb/lib/auth'
import { useNavigate } from 'react-router-dom'

interface Props {
  provider?: SignicatProvider
  errorContent: React.ReactNode
  tryAgain?: () => Promise<void>
  goBack?: string
}

export const SignicatError = ({ provider, errorContent, tryAgain, goBack }: Props) => {
  const [retrying, setRetrying] = React.useState(false)
  const navigate = useNavigate()
  const { spacing } = useCustomTheme()
  const Icon = getProviderIcon(provider)

  const handleRetry = async () => {
    setRetrying(true)

    await tryAgain()

    setRetrying(false)
  }

  const handleClose = () => {
    try {
      const item = localStorage.getItem('current-bankid-window-path')
      if (item) {
        localStorage.removeItem('current-bankid-window-path')
        navigate('/konto')
      } else {
        window.close()
      }
    } catch {}
  }

  return (
    <Pane display='flex' flexDirection='column' width='100%'>
      <Pane display='flex' alignItems='center' justifyContent='center' columnGap={spacing.xs}>
        <WarningSignIcon color='warning' size={74} />
        {Icon && <Icon height={36} />}
      </Pane>
      <Pane paddingY={spacing.m} textAlign='center'>
        {errorContent}
      </Pane>
      {(tryAgain || goBack) && (
        <Pane width='100%' display='flex' justifyContent='center' gap={spacing.xxs} marginTop={spacing.xl}>
          {tryAgain && (
            <Button appearance='primary' size='small' onClick={handleRetry} isLoading={retrying} width={200}>
              Prøv igjen
            </Button>
          )}
          {goBack && (
            <Button size='small' alignSelf='center' onClick={handleClose}>
              Lukk
            </Button>
          )}
        </Pane>
      )}
    </Pane>
  )
}
