import deepmerge from 'deepmerge'
import {
  defaultTheme,
  DefaultTheme as Theme,
  Components,
  StyleProps,
  ButtonOwnProps,
  CheckboxOwnProps,
  FileCardOwnProps,
  FileUploaderOwnProps,
  GroupOwnProps,
  TextInputOwnProps,
  LinkOwnProps,
  RadioOwnProps,
  SelectOwnProps,
  SwitchOwnProps,
  TabOwnProps,
  TableCellOwnProps,
  TableRowOwnProps,
  TagInputOwnProps,
  TextDropdownButtonOwnProps,
  AlertOwnProps,
  PaneOwnProps,
  DefaultThemeColors,
  Color,
  DefaultThemeFill,
  Fill,
  useTheme as useEGTheme,
} from 'evergreen-ui'
import { lighten, parseToRgb } from 'polished'
import { DeepPartial } from './typescriptUtils'
import tokens from './tokens/tokens.json'
/**
 * A lot of typing overriding here to be compatible with our previously typed theme
 * I've submitted an issue in EG repo to update theme to be more compatible with what is really supported:
 * https://github.com/segmentio/evergreen/issues/1465
 */
type AppearanceProp<C extends Components = Components> = C extends 'Alert'
  ? AlertOwnProps
  : C extends 'Button'
    ? ButtonOwnProps
    : C extends 'Checkbox'
      ? CheckboxOwnProps
      : C extends 'FileCard'
        ? FileCardOwnProps
        : C extends 'FileUploader'
          ? FileUploaderOwnProps
          : C extends 'Group'
            ? GroupOwnProps
            : C extends 'Input'
              ? TextInputOwnProps
              : C extends 'Link'
                ? LinkOwnProps
                : C extends 'Radio'
                  ? RadioOwnProps
                  : C extends 'Select'
                    ? SelectOwnProps
                    : C extends 'Switch'
                      ? SwitchOwnProps
                      : C extends 'Tab'
                        ? TabOwnProps
                        : C extends 'TableCell'
                          ? TableCellOwnProps
                          : C extends 'TableRow'
                            ? TableRowOwnProps
                            : C extends 'TagInput'
                              ? TagInputOwnProps
                              : C extends 'TextDropdownButton'
                                ? TextDropdownButtonOwnProps
                                : PaneOwnProps

type CustomStyleProps<T extends Components = Components> =
  | ((theme: ThemeOverrides, props: AppearanceProp<T>) => Partial<StyleProps<T>>)
  | Partial<StyleProps<T>>

type CustomComponentStyle<T extends Components = Components> = {
  baseStyle?: CustomStyleProps<T>
  appearances?: { [appearance: string]: CustomStyleProps<T> }
  sizes?: { [size: string | number]: CustomStyleProps<T> }
}

type CustomComponentsStyles<T extends Components = Components> = {
  [Component in T]: Partial<CustomComponentStyle<Component>>
}

type Colors =
  | DefaultThemeColors
  | 'fiDark'
  | 'fiDark70'
  | 'fiDark40'
  | 'fiDark20'
  | 'fiDark10'
  | 'fiTint'
  | 'fiBlue'
  | 'fiBlue70'
  | 'fiBlue40'
  | 'fiBlue20'
  | 'fiBlue10'
  | 'fiGreen'
  | 'fiGreen70'
  | 'fiGreen40'
  | 'fiGreen20'
  | 'fiGreen10'
  | 'fiRed'
  | 'fiRed70'
  | 'fiRed40'
  | 'fiRed20'
  | 'fiRed10'
  | 'fiTint'
  | 'fiText'
  | 'White'
  | 'fiGradient1'
  | 'fiGradient2'

type ColorsOverrides = {
  [color in Colors]: Color
} & {
  actionField: Color<{
    background: string
    border: string
  }>
  alert: Color<{
    error: string
    errorBackground: string
    info: string
    infoBackground: string
  }>
  issuer: Color<{
    blueGray20: string
    blueGray10: string
    blueGrayTint: string
  }>
  border: Color<{ default: string; muted: string }>
  campaignCard: Color<{
    missingImageIcon: string
  }>
  icon: Color<{
    default: string
    muted: string
    disabled: string
    selected: string
  }>
  progress: Color<{
    default: string
    filled: string
  }>
  text: Color<{
    default: string
    danger: string
    info: string
    link: string
    success: string
  }>
}

type Fills = { [F in DefaultThemeFill]: Fill }

interface OurOverrides {
  components: CustomComponentsStyles
  colors: ColorsOverrides
  spacing: Spacing
  fills: Fills
  constants: { headerHeight: number }
}

interface PartialOverrides {
  components: Partial<CustomComponentsStyles>
  colors: DeepPartial<ColorsOverrides>
  spacing: Partial<Spacing>
  fills: Partial<Fills>
  constants: { headerHeight: number }
}

export type ThemeOverrides = Omit<Theme, 'components' | 'colors'> & OurOverrides
type PartialThemeOverrides = Partial<Omit<Theme, 'components' | 'colors'>> & Partial<PartialOverrides>

interface Spacing {
  xl: string
  l: string
  m: string
  s: string
  xs: string
  xxs: string
}

const shadeHexColor = (
  hex: string,
  percentages: number[],
): { default: string; lighter: Record<number, string>; darker: Record<number, string> } => {
  const lighten = (color: number, percent: number) => Math.round(color + (255 - color) * (percent / 100))
  const darken = (color: number, percent: number) => Math.round(color * (1 - percent / 100))

  // Convert hex to RGB
  const r = Number.parseInt(hex.substring(1, 3), 16)
  const g = Number.parseInt(hex.substring(3, 5), 16)
  const b = Number.parseInt(hex.substring(5, 7), 16)

  const shades = {
    default: hex,
    lighter: {} as Record<number, string>,
    darker: {} as Record<number, string>,
  }

  percentages.forEach((percent) => {
    const newR = lighten(r, percent)
    const newG = lighten(g, percent)
    const newB = lighten(b, percent)
    const lighterHex = `#${((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1)}`

    const newRD = darken(r, percent)
    const newGD = darken(g, percent)
    const newBD = darken(b, percent)
    const darkerHex = `#${((1 << 24) + (newRD << 16) + (newGD << 8) + newBD).toString(16).slice(1)}`

    shades.lighter[percent] = lighterHex
    shades.darker[percent] = darkerHex
  })

  return shades
}

const brandColors = tokens.folkeinvest.Brand
const colorSteps = [30, 60, 80, 90, 95] // Percentage lighter/darker
const colors1 = shadeHexColor(brandColors.Dark.value, colorSteps)
const colors2 = shadeHexColor(brandColors.Blue.value, colorSteps)
const colors3 = shadeHexColor(brandColors.Green.value, colorSteps)
const colors4 = shadeHexColor(brandColors.Red.value, colorSteps)

const folkeinvestColors: PartialThemeOverrides['colors'] = {
  fiDark: colors1.default,
  fiDark70: colors1.lighter[30],
  fiDark40: colors1.lighter[60],
  fiDark20: colors1.lighter[80],
  fiDark10: colors1.lighter[90],
  fiTint: colors1.lighter[95],
  fiText: brandColors.Text.value,
  fiBlue: colors2.default,
  fiBlue70: colors2.lighter[30],
  fiBlue40: colors2.lighter[60],
  fiBlue20: colors2.lighter[80],
  fiBlue10: colors2.lighter[90],
  fiGreen: colors3.default,
  fiGreen70: colors3.lighter[30],
  fiGreen40: colors3.lighter[60],
  fiGreen20: colors3.lighter[80],
  fiGreen10: colors3.lighter[90],
  fiRed: colors4.default,
  fiRed70: colors4.lighter[30],
  fiRed40: colors4.lighter[60],
  fiRed20: colors4.lighter[80],
  fiRed10: colors4.lighter[90],
  white: brandColors.White.value,
  fiGradient1: brandColors['Background Gradients']['Faded Gradient'].value,
  fiGradient2: brandColors['Background Gradients']['Faded Gradient (Reverse)'].value,
  issuer: {
    blueGray20: brandColors.Issuer['Blue Gray 20'].value,
    blueGray10: brandColors.Issuer['Blue Gray 10'].value,
    blueGrayTint: brandColors.Issuer.Tint.value,
  },
}

const semanticColors: PartialThemeOverrides['colors'] = {
  actionField: {
    background: folkeinvestColors.fiTint,
    border: folkeinvestColors.fiDark40,
  },
  campaignCard: {
    missingImageIcon: folkeinvestColors.fiBlue10,
  },
  progress: {
    default: folkeinvestColors.fiBlue10,
    filled: folkeinvestColors.fiBlue40,
  },
  alert: {
    error: folkeinvestColors.fiRed70,
    errorBackground: folkeinvestColors.fiTint,
    info: folkeinvestColors.fiBlue70,
    infoBackground: folkeinvestColors.fiTint,
  },
  icon: {
    default: folkeinvestColors.fiDark,
    disabled: folkeinvestColors.fiTint,
    muted: folkeinvestColors.fiDark70,
    selected: folkeinvestColors.fiBlue70,
  },
  text: {
    default: folkeinvestColors.fiText,
    danger: folkeinvestColors.fiRed70,
    link: folkeinvestColors.fiBlue70,
  },
}

const sharedTheme: Pick<PartialThemeOverrides, 'fontFamilies' | 'spacing'> = {
  fontFamilies: {
    display: `${tokens.folkeinvest.fontFamilies.inter.value}, sans-serif`,
    ui: `${tokens.folkeinvest.fontFamilies.inter.value}, sans-serif`,
    mono: `${tokens.folkeinvest.fontFamilies.quicksand.value}, sans-serif`,
  },
  spacing: {
    xl: tokens.folkeinvest.xl.value,
    l: tokens.folkeinvest.l.value,
    m: tokens.folkeinvest.m.value,
    s: tokens.folkeinvest.s.value,
    xs: tokens.folkeinvest.xs.value,
    xxs: tokens.folkeinvest.xxs.value,
  },
}

const customTheme: PartialThemeOverrides = {
  ...sharedTheme,
  radii: ['0px', '2px', '4px', '6px', '8px', '10px'],
  //colors: folkeinvestColors,
  constants: { headerHeight: 60 },
  colors: deepmerge(folkeinvestColors, semanticColors),
  components: {
    Alert: {
      appearances: {
        default: (theme, props) => {
          let color = theme.colors.fiDark

          switch (props.intent) {
            case 'danger': {
              color = theme.colors.fiRed
              break
            }
            case 'info': {
              color = theme.colors.fiBlue
              break
            }
            case 'success': {
              color = theme.colors.fiGreen
              break
            }
            case 'warning': {
              color = theme.colors.fiDark
              break
            }
          }

          const rgb = parseToRgb(color)

          return {
            border: `1px solid ${color}`,
            backgroundColor: `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0.05)`,
            transform: 'all .3s cubic-bezier(0.4, 0, 0.2, 1)',
            color,
          }
        },
      },
    },
    Button: {
      baseStyle: (theme) => {
        const rgb = parseToRgb(theme.colors.fiDark)

        return {
          fontFamily: sharedTheme.fontFamilies?.display,
          fontWeight: '600',
          fontStretch: 'wdth: 600',
          borderRadius: '40px',
          boxShadow: `0px 6px 12px rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0)`,
          transition: 'all 0.2s',
          selectors: {
            _hover: {
              boxShadow: `0px 6px 12px rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0.2)`,
            },
            _disabled: {
              cursor: 'not-allowed',
            },
          },
        }
      },
      appearances: {
        default: (theme, props) => {
          let color = theme.colors.fiDark

          switch (props.intent) {
            case 'danger': {
              color = theme.colors.fiRed
              break
            }
            case 'info': {
              color = theme.colors.fiBlue
              break
            }
            case 'success': {
              color = theme.colors.fiGreen
              break
            }
            case 'warning': {
              color = theme.colors.fiDark
              break
            }
          }

          return {
            border: `1px solid ${color}`,
            backgroundColor: 'transparent',
            transform: 'all .3s cubic-bezier(0.4, 0, 0.2, 1)',
            color,
            selectors: {
              _hover: {
                backgroundColor: theme.colors.white,
                border: `1px solid ${color}`,
              },
              _disabled: {
                border: `1px solid ${theme.colors.fiDark40}`,
                color: theme.colors.fiDark40,
              },
            },
          }
        },
        primary: (theme, props) => {
          let color = theme.colors.fiDark

          switch (props.intent) {
            case 'danger': {
              color = theme.colors.fiRed
              break
            }
            case 'info': {
              color = theme.colors.fiBlue
              break
            }
            case 'success': {
              color = theme.colors.fiGreen
              break
            }
            case 'warning': {
              color = theme.colors.fiDark
              break
            }
          }

          return {
            backgroundColor: color,
            borderColor: color,
            color: theme.colors.white,
            selectors: {
              _disabled: {
                backgroundColor: theme.colors.fiDark40,
                borderColor: theme.colors.fiDark40,
              },
            },
          }
        },
        invertedPrimary: (theme, props) => {
          let color = theme.colors.fiDark

          switch (props.intent) {
            case 'danger': {
              color = theme.colors.fiRed
              break
            }
            case 'info': {
              color = theme.colors.fiBlue
              break
            }
            case 'success': {
              color = theme.colors.fiGreen
              break
            }
            case 'warning': {
              color = theme.colors.fiRed
              break
            }
          }

          return {
            backgroundColor: theme.colors.white,
            borderColor: theme.colors.white,
            color,
            selectors: {
              _disabled: {
                backgroundColor: theme.colors.fiDark40,
                borderColor: theme.colors.fiDark40,
              },
            },
          }
        },
        text: (theme) => ({
          background: 'none',
          border: 'none',
          boxShadow: 'none',
          color: theme.colors.fiText,
          paddingX: 10,
          selectors: {
            _hover: {
              background: 'none',
              boxShadow: 'none',
              color: theme.colors.fiBlue,
            },
            _active: {
              background: 'none',
              boxShadow: 'none',
              color: theme.colors.fiBlue,
            },
          },
        }),
      },
      sizes: {
        small: {
          height: 40,
          paddingLeft: 25,
          paddingRight: 25,
          paddingTop: 5,
          paddingBottom: 5,
          fontSize: '17px',
          lineHeight: '30px',
        },
        medium: {
          height: 54,
          fontSize: '20px',
          lineHeight: '30px',
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
    },
    Checkbox: {
      appearances: {
        default: (theme) => ({
          selectors: {
            _base: {
              background: theme.colors.white,
              color: theme.colors.white,
              border: `2px solid ${theme.colors.fiDark}`,
              boxShadow: 'none',
              borderRadius: theme.radii[2],
              width: '18px',
              height: '18px',
              fontSize: '16px',
              alignItems: 'center',
              marginTop: '4px',
            },
            _checked: {
              background: theme.colors.fiDark,
            },
            _hover: {
              background: 'inherit',
            },
            _checkedHover: {
              background: theme.colors.fiDark,
            },
            _checkedActive: {
              background: theme.colors.fiDark,
            },
            _disabled: {
              background: theme.colors.fiDark20,
              color: theme.colors.fiDark20,
              borderColor: theme.colors.fiDark40,
            },
          },
        }),
      },
    },
    ['DialogHeader' as any]: {
      // Must do this until evergreen updates components type to include the missing components
      baseStyle: {
        padding: 40,
        paddingBottom: 0,
      },
    },
    Group: {
      baseStyle: (theme) => ({
        backgroundColor: theme.colors.white,
        selectors: {
          _child: {
            '[data-active=true]': {
              color: theme.colors.fiBlue,
            },
          } as any,
          _firstChild: {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          },
          _lastChild: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 4,
            borderTopRightRadius: 4,
          },
        },
      }),
    },
    Heading: {
      baseStyle: (theme) => ({
        color: theme.colors.fiDark,
      }),
      sizes: {
        '900': {
          fontSize: '72px',
          lineHeight: '75px',
          fontWeight: '700',
          marginBottom: '30px',
        },
        '800': {
          fontSize: '60px',
          lineHeight: '71px',
          fontWeight: '700',
          marginBottom: '40px',
        },
        '700': {
          fontSize: '50px',
          lineHeight: '71px',
          fontWeight: '700',
          marginBottom: '40px',
        },
        '600': {
          fontSize: '36px',
          lineHeight: '43,57px',
          fontWeight: '700',
          marginBottom: '18px',
        },
        '500': {
          fontSize: '24px',
          lineHeight: '29.05px',
          fontWeight: '700',
          marginBottom: '18px',
        },
        '400': {
          fontSize: '22px',
          lineHeight: '26.63px',
          fontWeight: '600',
          marginBottom: '18px',
        },
        '300': {
          fontSize: '18px',
          lineHeight: '21.78px',
          fontWeight: '600',
          marginBottom: '18px',
        },
      },
    },
    Input: {
      sizes: {
        medium: {
          paddingY: 12,
          paddingX: 13,
          fontSize: '16px',
          height: '50px',
          lineHeight: '24px',
        },
      },
    },
    Label: {
      baseStyle: (theme) => {
        return {
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          color: theme.colors.fiDark,
        }
      },
    },
    Link: {
      baseStyle: (theme) => ({
        color: theme.colors.fiBlue,
        textDecoration: 'underline',
        selectors: {
          _hover: {
            color: theme.colors.fiBlue,
            textDecoration: 'none',
          },
        },
      }),
      sizes: {
        '300': {
          fontSize: '18px',
          lineHeight: '28px',
          fontWeight: '400',
        },
      },
      appearances: {
        primary: {
          color: 'white',
        },
      },
    },
    Paragraph: {
      sizes: {
        '500': {
          fontSize: '24px',
          lineHeight: '33.48px',
          fontWeight: '400',
        },
        '400': {
          fontSize: '18px',
          lineHeight: '28px',
          fontWeight: '400',
        },
        '300': {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '400',
        },
      },
    },
    Radio: {
      appearances: {
        default: (theme) => ({
          selectors: {
            _base: {
              background: 'white',
              color: 'white',
              border: `2px solid ${theme.colors.fiDark}`,
              boxShadow: 'none',
              borderRadius: '999px',
              width: '18px',
              height: '18px',
              fontSize: '16px',
              lineHeight: '24px',
            },
            _checked: {
              background: theme.colors.fiDark,
            },
            _checkedHover: {
              background: theme.colors.fiDark,
            },
            _checkedActive: {
              background: theme.colors.fiDark,
            },
            _disabled: {
              background: theme.colors.fiDark10,
            },
            _checkedDisabled: {
              background: theme.colors.fiDark10,
              color: theme.colors.fiDark,
            },
          },
        }),
      },
    },
    Select: {
      sizes: {
        medium: {
          height: '50px',
          fontSize: '16px',
          lineHeight: '24px',
          paddingY: 12,
          paddingX: 13,
        },
      },
    },
    Tab: {
      appearances: {
        primary: (theme) => {
          return {
            backgroundColor: theme.colors.fiDark,
            color: theme.colors.white,
            borderRadius: 10,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            '&:firstChild': {
              borderTopLeftRadius: 10,
            },
            '&:lastChild': {
              borderTopRightRadius: 10,
            },
            selectors: {
              _before: {
                backgroundColor: 'none',
              },
              _current: {
                paddingTop: 7,
                fontWeight: '600',
                borderBottom: `7px solid ${theme.colors.fiDark40}`,
              },
            },
          }
        },
        secondary: (theme) => {
          return {
            height: '100%',
            borderBottom: '4px solid transparent',
            selectors: {
              _hover: {
                backgroundColor: 'none',
              },
              _current: {
                borderColor: theme.colors.fiBlue,
              },
            },
          }
        },
      },
    },
    TableCell: {
      baseStyle: ({ colors }) => ({
        fontFamily: `'Inter', sans-serif`,
        fontSize: '14px',
        background: colors.issuer.blueGrayTint,
        lineHeight: '20px',
        color: colors.fiText,
      }),
    },
    Text: {
      appearances: {
        minimal: {
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
      sizes: {
        '500': {
          fontSize: '24px',
          lineHeight: '33.48px',
          fontWeight: '400',
        },
        '400': {
          fontSize: '18px',
          lineHeight: '28px',
          fontWeight: '400',
        },
        '300': {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '400',
        },
      },
    },
  },
}

const mergeOptions: deepmerge.Options = {
  arrayMerge: (_, src) => src,
}

const issuerThemeOverrides: PartialThemeOverrides = {
  components: {
    Input: {
      sizes: {
        medium: {
          height: 50,
        },
      },
    },
    Label: {
      sizes: {
        '400': {
          fontSize: 14,
        },
      },
    },
  },
}

const facilitatorThemeOverrides: PartialThemeOverrides = {
  ...sharedTheme,
  colors: deepmerge(folkeinvestColors, semanticColors),
  radii: ['0px', '2px', '4px', '6px', '8px', '10px'],
  constants: {
    headerHeight: 60,
  },
  components: {
    Heading: {
      baseStyle: (theme) => ({
        color: theme.colors.gray700,
      }),
    },
    Tooltip: {
      baseStyle: {
        paddingY: '4px',
        paddingX: '16px',
        borderRadius: '8px',
      },
    },
    Button: {
      appearances: {
        primary: (theme, props) => {
          let backgroundColor = theme.colors.blue500

          switch (props.intent) {
            case 'success': {
              backgroundColor = theme.colors.green500
              break
            }
            case 'info': {
              backgroundColor = theme.colors.blue500
              break
            }
            case 'warning': {
              backgroundColor = theme.colors.orange500
              break
            }
            case 'danger': {
              backgroundColor = theme.colors.red500
              break
            }
          }

          return {
            backgroundColor,
            color: 'white',
            fontWeight: '600',
            borderRadius: theme.radii[2],
            selectors: {
              _hover: {
                backgroundColor: lighten(0.05, backgroundColor),
              },
              _disabled: {
                opacity: 0.2,
              },
            },
            '& > div > svg': {
              fill: 'white',
            },
          }
        },
        minimal: (theme, props) => {
          let color = undefined

          switch (props.intent) {
            case 'success': {
              color = theme.colors.green500
              break
            }
            case 'info': {
              color = theme.colors.blue500
              break
            }
            case 'warning': {
              color = theme.colors.orange500
              break
            }
            case 'danger': {
              color = theme.colors.red500
              break
            }
          }

          return {
            borderRadius: theme.radii[2],
            color,
          }
        },
        default: (theme) => ({
          backgroundColor: theme.colors.gray100,
          color: theme.colors.gray800,
          border: `1px solid ${theme.colors.gray500}`,
          borderRadius: theme.radii[2],
        }),
      },
    },
    Input: {
      appearances: {
        default: (theme) => ({
          borderRadius: theme.radii[1],
          lineHeight: '16px',
          height: '32px',
          color: theme.colors.gray800,
        }),
      },
      sizes: {
        medium: {
          paddingY: 8,
          paddingX: 12,
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
    },
    Link: {
      baseStyle: (theme) => ({
        color: theme.colors.blue500,
        textDecoration: 'none',
        selectors: {
          _hover: {
            color: theme.colors.blue500,
          },
        },
      }),
    },
    Select: {
      appearances: {
        default: (theme) => ({
          border: `1px solid ${theme.colors.gray400}`,
          borderRadius: theme.radii[1],
          lineHeight: '14px',
          height: '32px',
          fontSize: '12px',
          paddingX: 12,
          paddingY: 8,
          color: theme.colors.gray800,
          background: 'white',
          '& + svg': {
            border: `1px solid ${theme.colors.gray100}`,
            borderRadius: theme.radii[1],
            backgroundColor: theme.colors.gray100,
            padding: '4px',
            fill: theme.colors.gray600,
          },
          selectors: {
            _hover: {
              borderColor: theme.colors.gray600,
              backgroundColor: 'white',
            },
          },
        }),
      },
    },
    TableRow: {
      appearances: {
        default: () => ({
          background: 'inherit',
        }),
      },
    },
    TableCell: {
      appearances: {
        default: () => ({
          fontFamily: 'inherit',
          fontSize: 'inherit',
          background: 'inherit',
          lineHeight: '20px',
        }),
      },
    },
  },
}

export const clientTheme = deepmerge(defaultTheme, customTheme, mergeOptions) as ThemeOverrides

export const issuerTheme = deepmerge(clientTheme, issuerThemeOverrides, mergeOptions) as ThemeOverrides

export const facilitatorTheme = deepmerge(defaultTheme, facilitatorThemeOverrides, mergeOptions) as ThemeOverrides

export const useCustomTheme = (): ThemeOverrides => (useEGTheme as any)()
