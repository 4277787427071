import { Button, Pane, Paragraph, Spinner, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import * as React from 'react'
import styled from 'styled-components'
import { Divider } from '/fiweb/components'
import { MitIdLogo, SwedishBankIdLogo } from '/fiweb/components/Icons'
import { auth, SignicatProvider } from '/fiweb/lib/auth'
import { config } from '/~/utils/config'
import { SE, DK } from 'country-flag-icons/react/3x2'

interface Props {
  signup?: boolean
  actionText: string
}

const SignicatButton = styled(Button).attrs({ size: 'small' })`
display:flex; 
padding-left: 9px;
width: 275px;
justify-content:space-between;
  font-size: 12px;
  gap: 18px;
  color: ${({ theme: { colors } }) => colors.fiDark70};
  .flag{
    height: 24px;
    border-radius: 20px;
  }
`

export const InternationalActions = ({ actionText, signup }: Props) => {
  const [currentService, setCurrentService] = React.useState<SignicatProvider | null>(null)
  const { spacing, colors } = useCustomTheme()

  const handleClick = async (service: SignicatProvider) => {
    setCurrentService(service)

    const res = await auth(config.authHost).obtainSignicatSessionUrl(
      [service],
      `${window.origin}${window.location.pathname}`.replace(/\/$/, ''),
    )

    if (res.ok) {
      const url = await res.text()

      window.location.href = url
    }

    setCurrentService(null)
  }

  const isLoading = !!currentService

  return config.signicatInternationalEnabled ? (
    <Pane
      width='100%'
      paddingY={spacing.m}
      display='flex'
      flexDirection='column'
      alignItems='center'
      rowGap={spacing.xs}
      padding={spacing.m}
    >
      <Divider width='100%' />
      {signup ? (
        <Paragraph size={300} textAlign='center' color={colors.fiDark40} marginBottom={spacing.xxs}>
          Er du dansk statsborger? {actionText} med MitID i stedet!
        </Paragraph>
      ) : (
        <Paragraph size={300} textAlign='center' color={colors.fiDark40} marginBottom={spacing.xxs}>
          Er du dansk eller svensk statsborger? {actionText} med MitID eller Svensk BankID i stedet!
        </Paragraph>
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SignicatButton
            onClick={() => (currentService ? undefined : handleClick(SignicatProvider.DANISH_MIT_ID))}
            disabled={isLoading}
            style={isLoading ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
          >
            <DK
              className='flag'
              aria-label='Logg deg inn med danskt MitID'
              style={isLoading ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
            />
            <Text display='flex' gap={spacing.xxs} fontSize={17} fontWeight={600}>
              {actionText} med <MitIdLogo width={60} />
            </Text>
          </SignicatButton>
          {!signup && (
            <SignicatButton
              onClick={() => (currentService ? undefined : handleClick(SignicatProvider.SWEDISH_BANK_ID))}
              disabled={isLoading}
              style={isLoading ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
            >
              <SE className='flag' aria-label='Logga in med svenskt BankID' />
              <Text display='flex' gap={spacing.xxs} fontSize={17} fontWeight={600}>
                {actionText} med <SwedishBankIdLogo width={60} />
              </Text>
            </SignicatButton>
          )}
        </>
      )}
    </Pane>
  ) : null
}
